import './App.css';
import React from 'react';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import routes from "./Routes";


const router = createBrowserRouter(routes.routesForReactRouterDom());

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
