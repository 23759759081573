import {LayeredText, Link, Text} from "@poap/poap-components";
import {PoapStationFooter} from "../components/PoapStationFooter";


export const ServerError = () => {
  return (
    <div className="error-section-container">
      <div className="error-image-container">
        <img className="server-error-img" alt="server error illustration" src="/server-error.svg"/>
      </div>
      <div className="error-section-text-container">
        <div className="spark-container">
          <div className="violet-colored-text">
            <LayeredText label="oops!"/>
          </div>
          <img className="spark-open" alt="spark illustation" src="/spark.svg"/>
        </div>
        <LayeredText label="Internal server"/>
        <div className="pink-colored-text">
          <div className="spark-container">
            <LayeredText label="error"/>
            <img className="spark-close" alt="spark illustation" src="/spark.svg"/>
          </div>
        </div>
        <div className="base-text-container">
          <Text>
            Please try again or visit
            <Link href="https://poap.xyz" className="visit-poap-link">poap.xyz</Link>
            to contact our support team by opening the support chat bubble
          </Text>
        </div>
      </div>
      <PoapStationFooter/>
    </div>
  )
};
