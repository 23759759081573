import {Button, DynamicTag, Link, Text} from "@poap/poap-components";
import {Spinner} from "./Spinner";
import routes from "../Routes";


export const PayNotification = (props) => {
  const {loading, error, eventId} = props;

  const successCheckout =
    <>
      <img className="voucher-success" alt="notification success" src="/voucher-success.svg"/>
      <div className="pay-notification-text-container">
        <DynamicTag tag="h2">Purchase Successful</DynamicTag>
        <Text>Look for the POAP Coin Machine, scan this QR code and claim your POAP coin!</Text>
      </div>
    </>;

  const failedCheckout =
    <>
      <img className="voucher-success" alt="notification success" src="/voucher-fail.svg"/>
      <div className="pay-notification-text-container">
        <DynamicTag tag="h2">Purchase Failed</DynamicTag>
        <Text>
          Please try again or visit
          <Link href="https://poap.xyz" className="visit-poap-link">poap.xyz</Link>
          to contact our support team by opening the support chat bubble
        </Text>
      </div>
      <Button className="checkout-fail-try-again-button" variation="white" href={routes.getVoucherForEvent(eventId)}>
        Try again
      </Button>
    </>;

  return (
    <>
      <div className="pay-notification-container">
        <img className="pay-notification-background" alt="pay notification background"
             src="/pay-notification-background.svg"/>
        {loading && <Spinner/>}
        {!loading && error && failedCheckout}
        {!loading && !error && successCheckout}
      </div>
    </>
  );
};
