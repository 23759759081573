import {CardContainer, DynamicTag, Text} from "@poap/poap-components";
import {Spinner} from "./Spinner";
import {SendVoucher} from "./SendVoucher";
import {ShareButton} from "./ShareButton";


export const Voucher = (props) => {
  const {qrData, eventLoading, event, voucherUuid} = props;
  return (eventLoading) ?
    <Spinner/> :
    <>
      <div className="voucher-card-container">
        <CardContainer>
          <DynamicTag tag="h3">{event.name}</DynamicTag>
          <img className="voucher-qr" alt="QR code" src={qrData}/>
          <ShareButton event={event} qrData={qrData}/>
        </CardContainer>
        <SendVoucher voucherUuid={voucherUuid}/>
      </div>
    </>
    ;
};
