import {useEligiblePoapEventImg} from "../hooks/useEligiblePoapEventImg";
import {Spinner} from "./Spinner";
import {useState} from "react";

export const EligiblePoapEventImage = (props) => {
  const {poapEventId} = props;
  const {imageUrl, urlLoading} = useEligiblePoapEventImg(poapEventId);
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <div className="eligible-poap">
      {(urlLoading || imgLoading) && <Spinner/>}
      {!urlLoading &&
        <img style={{display: (imgLoading) ? "none" : "block"}}
             onLoad={() => setImgLoading(false)}
             key={poapEventId}
             src={imageUrl}
             alt="POAP"/>}
    </div>
  );
}
