import {PoapStationFooter} from "../components/PoapStationFooter";
import {LayeredText, Link, Text} from "@poap/poap-components";

export const NotFound = () => {
  return (
    <div className="error-section-container">
      <div className="error-image-container">
        <img alt="error 404 illustration" src="/error404.svg"/>
      </div>
      <div className="error-section-text-container">
        <div className="spark-container">
          <div className="violet-colored-text">
            <LayeredText label="HMMM..."/>
          </div>
          <img className="spark-open" alt="spark illustation" src="/spark.svg"/>
          <img className="spark-close" alt="spark illustation" src="/spark.svg"/>
        </div>
        <LayeredText label="This link appears to not be valid"/>
        <div className="pink-colored-text">
          <LayeredText label="Check it again to be sure"/>
        </div>
        <div className="base-text-container">
          <Text>
            Please try again or visit
            <Link href="https://poap.xyz" className="visit-poap-link">poap.xyz</Link>
            to contact our support team by opening the support chat bubble
          </Text>
        </div>
      </div>
      <PoapStationFooter/>
    </div>
  );
}
