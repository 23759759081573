import {GetVoucherIntro} from "../components/GetVoucherIntro";
import {PaymentMethods} from "../components/PaymentMethods";
import {useEvent} from "../hooks/useEvent";
import {Spinner} from "../components/Spinner";
import {useParams} from "react-router-dom";
import {PoapStationFooter} from "../components/PoapStationFooter";
import {ClaimMethods} from "../components/ClaimMethods";
import {PoapStationHeader} from "../components/PoapStationHeader";


export const GetVoucher = () => {
  const params = useParams();
  const eventId = params.eventId;
  const {event, loading} = useEvent(eventId);
  const component =
    <>
      <PoapStationHeader eventId={eventId}/>
      <GetVoucherIntro event={event}/>
      <PaymentMethods event={event}/>
      <ClaimMethods event={event}/>
      <PoapStationFooter/>
    </>;
  return (
    <div className="app-main-container">
      {loading && <Spinner/>}
      {!loading && component}
    </div>
  )
};
