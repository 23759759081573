class BackofficeApiClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  paymentMethodLink(eventId, paymentMethod) {
    return `${this.baseURL}/events/event/${eventId}/voucher/buy?method=${paymentMethod.name}`;
  }

  async request(endpoint, method = 'GET', body = null) {
    let options = {
      method,
      headers: {'Content-Type': 'application/json'}
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${this.baseURL}${endpoint}`, options);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response;
  }

  async getEvent(eventId) {
    const response = await this.request(`/events/event/${eventId}/`);
    const Currency = {
      usd: "U$D",
      eth: "ETH",
    }
    const serializedEvent = await response.json();
    return {
      id: serializedEvent.id,
      name: serializedEvent.name,
      description: serializedEvent.description,
      price: {
        currency: Currency[serializedEvent.currency],
        value: serializedEvent.voucher_price,
      },
      eligiblePoapEventIds: serializedEvent.eligible_poap_events || [],
      imageUrl: serializedEvent.image_url,
    };
  }

  async getVoucher(voucherUuid) {
    const response = await this.request(`/events/voucher_acquisition/${voucherUuid}/voucher/`);
    const serializedVoucher = await response.json();
    return {
      id: serializedVoucher.id,
      hasOwner: serializedVoucher.has_owner,
    };
  }

  async getImgSrc(poapEventId) {
    const response = await this.request(`/events/event/poap_event/${poapEventId}/`);
    return (await response.json())["image_url"];
  }

  async getQrImg(checkoutUuid) {
    const response = await this.request(`/events/voucher_acquisition/${checkoutUuid}/success/`);
    const arrayBuffer = await response.arrayBuffer();
    const base64String = btoa(
      new Uint8Array(arrayBuffer)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:image/png;base64,${base64String}`;
  }
}

const apiClient = new BackofficeApiClient("https://api.station.poap.xyz");
export default apiClient;
