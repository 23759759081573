import {AnimatedArrow, DynamicTag, Text} from "@poap/poap-components";
import {GetYourVoucherButton} from "./GetYourVoucherButton";
import {PriceBox} from "./PriceBox";


const EventBackground = () => {
  return (
    <div className="event-background-container">
      <img alt="background" src="/event_background.svg"/>
    </div>
  );
};

const EventImage = (props) => {
  const {imageUrl} = props;
  return (
    <div className="event-image-container">
      <img className="event-image" alt="event" src={imageUrl}/>
    </div>
  );
};

export const Hero = (props) => {
  const event = props.event;

  return (
    <>
      <div className="event-logo-container">
        <EventBackground/>
        <EventImage imageUrl={event.imageUrl}/>
      </div>
      <div className="hero-text">
        <DynamicTag className="hero-title">{event.name}</DynamicTag>
        <Text className="hero-description">{event.description}</Text>
      </div>
      <div className="button-container">
        <GetYourVoucherButton eventId={event.id}/>
      </div>
      <PriceBox value={event.price.value} currency={event.price.currency}/>
      <AnimatedArrow/>
    </>
  );
};
