import {Hero} from "../components/Hero";
import {HowDoPOAPsWork} from "../components/HowDoPOAPsWork";
import {CardSection} from "../components/CardSection";
import {useEvent} from "../hooks/useEvent";
import {Spinner} from "../components/Spinner";
import {useParams} from "react-router-dom";
import {PoapStationFooter} from "../components/PoapStationFooter";
import {PoapStationHeader} from "../components/PoapStationHeader";


export const Home = () => {
  const params = useParams();
  const eventId = params.eventId;
  const {event, loading} = useEvent(eventId);

  const home =
    <>
      <PoapStationHeader eventId={eventId}/>
      <Hero event={event}/>
      <HowDoPOAPsWork event={event}/>
      <CardSection event={event}/>
      <PoapStationFooter/>
    </>;
  return (
    <>
      <div className="app-main-container">
        {loading && <Spinner/>}
        {!loading && home}
      </div>
    </>
  );
}
