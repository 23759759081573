const Price = (props) => {
  const {value, currency} = props;
  return (
    <div style={{
      width: 300,
      height: 60,
      justifyContent: 'center',
      alignItems: 'flex-start',
      display: 'inline-flex',
      position: 'relative'
    }}>
      <div style={{
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 15,
        background: '#F4F3FF',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
        display: 'flex'
      }}>
        <div style={{
          textAlign: 'center',
          color: '#5E58A5',
          fontSize: 20,
          fontFamily: 'Rubik',
          fontWeight: '400',
          wordWrap: 'break-word'
        }}>
          1 POAP = {currency} {value}
        </div>
      </div>
    </div>
  )
}

export const PriceBox = (props) => {
  const {value, currency} = props;
  return (
    <div className="price-container">
      <Price value={value} currency={currency}/>
    </div>
  )
}
