import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useScript} from "@uidotdev/usehooks";
import backofficeApiClient from "../BackofficeApiClient";
import routes from "../Routes";


export const AuthenticateWithTokenproof = (props) => {
  const {eventId} = props;
  const navigate = useNavigate();
  const apiBaseUrl = backofficeApiClient.baseURL;
  const tokenproofLibLoadState = useScript("https://cdn.tokenproof.xyz/js/tokenproof-oa-widget-v1.0.js");
  const [tokenproofLib, setTokenproofLib] = useState();
  const [nonceEvent, setNonceEvent] = useState();
  const [checkoutUuid, setCheckoutUuid] = useState();
  const [authenticationResult, setAuthenticationResult] = useState();

  useEffect(() => {
    if (tokenproofLibLoadState === "ready") {
      // We need to ensure the tokenproof external js lib is loaded and initialized
      setTimeout(() => {
        window.tokenproof.on("nonce", (nonceEvent_) => {
          setNonceEvent(nonceEvent_);
        });
        setTokenproofLib(window.tokenproof);
      }, 100);
    }
  }, [tokenproofLibLoadState]);

  useEffect(() => {
    if (nonceEvent !== undefined) {
      // TODO: move this to BackofficeApiClient
      const generateVoucherAcquisition = async () => {
        const nonce = nonceEvent.current;
        const url = `${apiBaseUrl}/events/event/${eventId}/voucher/buy?method=tokenproof&nonce=${nonce}`;
        try {
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const responseData = await response.json();
          setCheckoutUuid(responseData.id);
        } catch (error) {
          console.error(error);
        }
      }
      generateVoucherAcquisition();
    }
  }, [nonceEvent, apiBaseUrl, eventId]);

  useEffect(() => {
    if (authenticationResult === "authenticated" && checkoutUuid !== undefined) {
      navigate(routes.checkoutForEvent(eventId, checkoutUuid), {replace: true});
    }
  }, [authenticationResult, checkoutUuid, eventId, navigate]);

  const authenticateWithTokenproof = async () => {
    const appId = "2fab3111-a48f-4725-8c65-621a21484657"; // TODO: this should not be placed here
    try {
      const result = await tokenproofLib.login({appId});
      setAuthenticationResult(result.status)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="button-container -long-btn">
      <button className="tokenproof-button" onClick={authenticateWithTokenproof}>
        <img src="https://cdn.tokenproof.xyz/img/tokenproofIconWhite.png" alt="tokenproof-button"/>
          <span>Authenticate with tokenproof</span>
      </button>
    </div>
  );
};
