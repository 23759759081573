import {DynamicTag, Text, LayeredText} from "@poap/poap-components";
import {GetYourVoucherButton} from "./GetYourVoucherButton";


export const HowDoPOAPsWork = (props) => {
  const event = props.event;

  return (
    <>
      <div className="section-container">
        <div className="section-title-container -pink-colored-text">
          <LayeredText label="How does"/>
          <LayeredText label="POAP Station"/>
          <LayeredText label="work?"/>
        </div>
        <div className="use-instructions">
          <div className="number-container">
            <LayeredText label="1"/>
            <img className="number-box" alt="coin" src="/number-box.svg"/>
          </div>
          <div className="info-container">
            <DynamicTag tag="h3">Buy a POAP Voucher</DynamicTag>
            <Text>
              Click the button below to pay with crypto or a credit card. Note that certain POAPs will get you a POAP Voucher for free!
            </Text>
          </div>
        </div>
        <div className="use-instructions">
          <div className="number-container">
            <LayeredText label="2"/>
            <img className="number-box" alt="coin" src="/number-box.svg"/>
          </div>
          <div className="info-container">
            <DynamicTag tag="h3">Redeem for POAP Coin</DynamicTag>
            <Text>
              After purchasing a POAP Voucher, scan it at the Coin Machine to receive a magic POAP Coin.
            </Text>
          </div>
        </div>
        <div className="use-instructions">
          <div className="number-container">
            <LayeredText label="3"/>
            <img className="number-box" alt="coin" src="/number-box.svg"/>
          </div>
          <div className="info-container">
            <DynamicTag tag="h3">Get your POAP + Prize</DynamicTag>
            <Text>
              Insert your POAP Coin into the Prize Machine to receive your POAP and a special prize!
            </Text>
          </div>
        </div>
        <div className="button-container">
          <GetYourVoucherButton eventId={event.id}/>
        </div>
      </div>

    </>
  );
};
