import {Navigate} from "react-router-dom";
import {Home} from "./pages/Home";
import {GetVoucher} from "./pages/GetVoucher";
import {ClaimVoucher} from "./pages/ClaimVoucher";
import {Checkout} from "./pages/Checkout";
import React from "react";
import {NotFound} from "./pages/NotFound";
import {ServerError} from "./pages/ServerError";

class Routes {
  routesForReactRouterDom() {
    const allRoutes = [
      {path: "/", element: <Navigate to="/event/1/" replace/>},
      {path: "/event/:eventId/", element: <Home/>},
      {path: "/event/:eventId/get-voucher/", element: <GetVoucher/>},
      {path: "/event/:eventId/claim-voucher/", element: <ClaimVoucher/>},
      {path: "/event/:eventId/checkout/:checkoutUuid/", element: <Checkout/>},
      {path: "/*", element: <NotFound/>},
    ];
    return allRoutes.map((route) => {
      route.errorElement = <ServerError/>;
      return route;
    });
  }

  homeForEvent(eventId) {
    return `/event/${eventId}/`;
  }

  getVoucherForEvent(eventId) {
    return `/event/${eventId}/get-voucher/`;
  }

  claimVoucherForEvent(eventId) {
    return `/event/${eventId}/claim-voucher/`;
  }

  checkoutForEvent(eventId, checkoutUuid) {
    return `/event/${eventId}/checkout/${checkoutUuid}/`;
  }
}

const routes = new Routes();
export default routes;
