import {Button, Input, Text} from "@poap/poap-components";
import {useState} from "react";
import {useVoucher} from "../hooks/useVoucher";
import backofficeApiClient from "../BackofficeApiClient";

export const SendVoucher = (props) => {
  const {voucherUuid} = props;
  const {voucher, loading} = useVoucher(voucherUuid);

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [alreadySent, setAlreadySent] = useState(false);

  function isValidEmail(email) {
    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    // validating if an email is valid is not trivial, so we use a simple regex here
    const simplifiedValidEmailRegex = /\S+@\S+\.\S+/;
    return simplifiedValidEmailRegex.test(email);
  }

  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setError("Enter a valid email");
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  }

  const handleSubmit = async () => {
    // TODO: move to backofficeApiClient
    setSubmitting(true);
    const apiUrl = `${backofficeApiClient.baseURL}/events/voucher_acquisition/${voucherUuid}/assign_owner/`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({owner_email: email}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSuccess("We'll send you an email with your voucher shortly!");
      setAlreadySent(true)
    } catch (error) {
      setError("Voucher was not sent. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  }

  return !loading && !voucher.hasOwner && (
    <div className="send-voucher">
      <Text className="text-input-label">Receive your POAP Voucher via email</Text>
      <Input
        value={email}
        onChange={handleChange}
        placeholder="email@example.com"
        success={success}
        error={error}
      />
      <Button onClick={handleSubmit} disabled={!email || !!error || submitting || alreadySent}>Send voucher</Button>
    </div>
  )
}
