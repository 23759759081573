import {Button, HeroIcon} from "@poap/poap-components";

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type: mime});
}


function shareContent(title, text, qrData) {
  if (navigator.share) {
    navigator.share({
      title: title,
      text: text,
      files: [new File([dataURLtoBlob(qrData)], 'POAP_Station_QRVoucher.png', {type: 'image/png'})]
    })
      .then(() => console.log("Successful share"))
      .catch((error) => console.error("Error sharing:", error));
  } else {
    console.error("Web Share API is not supported in your browser.");
  }
}

export const ShareButton = (props) => {
  const {event, qrData} = props;
  return (
    <div className="share-voucher-button">
      {navigator.share && <Button
        variation={"white"}
        onClick={() => shareContent(event.name, "Here is your POAP Station QR code voucher. Scan it at the Coin Machine to receive a magic POAP Coin.", qrData)}
        leftIcon={<HeroIcon className="share-icon" icon="share"/>}
        rightIcon={null}
      />}
    </div>
  )
}
