import {useParams} from 'react-router-dom';
import {useCallback} from "react";
import {PayNotification} from "../components/PayNotification";
import {Voucher} from "../components/Voucher";
import {useEvent} from "../hooks/useEvent";
import {PoapStationFooter} from "../components/PoapStationFooter";
import {PoapStationHeader} from "../components/PoapStationHeader";
import apiClient from "../BackofficeApiClient";
import {useApiCall} from "../hooks/useApiCall";


export const Checkout = () => {
  const params = useParams();
  const checkoutUuid = params.checkoutUuid;
  const eventId = params.eventId;
  const {event, loading: eventLoading} = useEvent(eventId);

  const getQrImg = useCallback(() => {
    return apiClient.getQrImg(checkoutUuid);
  }, [checkoutUuid]);

  const {data, loading, error} = useApiCall(getQrImg);

  return (
    <div className="app-main-container">
      <PoapStationHeader eventId={eventId}/>
      <PayNotification loading={loading} error={error} eventId={event?.id}/>
      {data && <Voucher eventLoading={eventLoading} qrData={data} event={event} voucherUuid={checkoutUuid}/>}
      <PoapStationFooter/>
    </div>
  )
}
