import {Button, DynamicTag, Sup} from "@poap/poap-components";
import backofficeApiClient from "../BackofficeApiClient";
import {PaymentMethod} from "../PaymentMethod";


const PaymentMethodButton = (props) => {
  const {eventId, paymentMethod} = props;
  return (
    <Button
      href={backofficeApiClient.paymentMethodLink(eventId, paymentMethod)}
      className={`payment-method-button-${paymentMethod.name}`}
      leftIcon={paymentMethod.iconUrl &&
        <img
          className={`payment-method-button-icon-${paymentMethod.name}`}
          alt={`${paymentMethod.name}`}
          src={paymentMethod.iconUrl}
        />
      }
    >
      {paymentMethod.description}
    </Button>
  )
}


export const PaymentMethods = (props) => {
  const {event} = props;
  return (
    <>
      <div className="section-container">
        <div className="subtitle-container -align-center">
          <DynamicTag tag="h3">Choose payment method</DynamicTag>
        </div>
        <div className="button-container -long-btn">
          <PaymentMethodButton eventId={event.id} paymentMethod={PaymentMethod.STRIPE}/>
          <Sup>Cards, Apple Pay and Google Pay via Stripe</Sup>
        </div>
        <div className="button-container -long-btn">
          <PaymentMethodButton eventId={event.id} paymentMethod={PaymentMethod.COINBASE}/>
          <Sup>Any asset on Ethereum and Polygon via Coinbase</Sup>
        </div>
      </div>
    </>
  );
};
