import {CardContainer, DynamicTag, LayeredText, Text} from "@poap/poap-components";
import {GetYourVoucherButton} from "./GetYourVoucherButton";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


export const CardSection = (props) => {
  const event = props.event;

  return (
    <div className="section-container">
      <div className="section-title-container -violet-colored-text">
        <LayeredText label="Why"/>
        <LayeredText label="POAP Station?"/>
      </div>
      <div className="card-container">
        <Carousel
          autoFocus={false}
          autoPlay={false}
          centerMode={true}
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          stopOnHover={false}
          emulateTouch={true}
          dynamicHeight={false}
          infiniteLoop={false}
          swipeable={true}
          preventMovementUntilSwipeScrollTolerance={false}
          axis={"horizontal"}
          centerSlidePercentage={70}
        >
          <CardContainer>
            <div className="card-image-container">
              <img className="card-image" alt="diamond" src="/diamond.svg"/>
            </div>
            <DynamicTag tag="h3" className="card-title">Boost loyalty and engagement</DynamicTag>
            <Text style={{marginTop: "1rem"}}>A POAP lives forever and so will the positive memories of collecting one at your event.</Text>
          </CardContainer>
          <CardContainer>
            <div className="card-image-container">
              <img className="card-image" alt="diamond" src="/diamond.svg"/>
            </div>
            <DynamicTag tag="h3" className="card-title">Offer unique branded merchandise</DynamicTag>
            <Text style={{marginTop: "1rem"}}>Combine digital collectibles with fun physical items, delivered with delightful nostalgia.</Text>
          </CardContainer>
          <CardContainer>
            <div className="card-image-container">
              <img className="card-image" alt="diamond" src="/diamond.svg"/>
            </div>
            <DynamicTag tag="h3" className="card-title">Innovate with emerging technology</DynamicTag>
            <Text style={{marginTop: "1rem"}}>Appeal to new audiences and cross over into the metaverse in a fun, safe manner.</Text>
          </CardContainer>
        </Carousel>
      </div>
      <div className="button-container">
        <GetYourVoucherButton eventId={event.id}/>
      </div>
    </div>
  );
};
