import {Footer} from "@poap/poap-components";

export const PoapStationFooter = (props) => {
  return (
    <Footer
      poap_branded
      terms="https://poap.xyz/terms-of-service"
      privacy="https://poap.xyz/privacy-policy"
      data="https://poap.xyz/data-policy"
    />
  )
}
