import {Oval} from "react-loader-spinner";

export const Spinner = () => {
  return (
    <Oval
      height={80}
      width={80}
      color="#473e6b"
      wrapperClass="spinner"
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#968cff"
      strokeWidth={5}
      strokeWidthSecondary={5}
    />
  )
}
