import {useCallback} from "react";
import apiClient from "../BackofficeApiClient";
import {useApiCall} from "./useApiCall";

export const useVoucher = (voucherUuid) => {
  const getVoucher = useCallback(() => {
    return apiClient.getVoucher(voucherUuid);
  }, [voucherUuid]);

  const {data, loading} = useApiCall(getVoucher);
  return {voucher: data, loading};
}
