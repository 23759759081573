export const PaymentMethod = {
  STRIPE: {
    name: 'stripe',
    description: 'Pay with Cards',
    iconUrl: '/stripe-icon.svg',
  },
  COINBASE: {
    name: 'coinbase',
    description: 'Pay with Crypto',
    iconUrl: '/coinbase-icon.svg',
  },
}
