import {useCallback} from "react";
import apiClient from "../BackofficeApiClient";
import {useApiCall} from "./useApiCall";

export const useEligiblePoapEventImg = (poapEventId) => {
  const getEventImgUrl = useCallback(() => {
    return apiClient.getImgSrc(poapEventId);
  }, [poapEventId]);

  const {data, loading} = useApiCall(getEventImgUrl);
  return {imageUrl: data, urlLoading: loading};
}
