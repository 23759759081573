import {DynamicTag, Sup} from "@poap/poap-components";
import {AuthenticateWithTokenproof} from "../components/AuthenticateWithTokenproof";
import {useEvent} from "../hooks/useEvent";
import {Spinner} from "../components/Spinner";
import {useParams} from "react-router-dom";
import {PoapStationFooter} from "../components/PoapStationFooter";
import {EligiblePoapEventImage} from "../components/EligiblePoapEventImage";
import {PoapStationHeader} from "../components/PoapStationHeader";


export const ClaimVoucher = () => {
  const params = useParams();
  const eventId = params.eventId;
  const {event, loading} = useEvent(eventId);
  return (
    <div className="app-main-container">
      {loading && <Spinner/>}
      {!loading && <>
        <PoapStationHeader eventId={eventId}/>
        <div className="section-container claim-with-tokenproof">
          <div className="subtitle-container -align-center">
            <DynamicTag tag="h2">Claim with tokenproof</DynamicTag>
            <DynamicTag tag="p">If you have any of the following POAPs you can get a POAP Voucher for free!</DynamicTag>
          </div>
          <div className="eligible-poap-container">
            {event.eligiblePoapEventIds.map(poapEventId => <EligiblePoapEventImage key={poapEventId}
                                                                                   poapEventId={poapEventId}/>)}
          </div>
          <AuthenticateWithTokenproof eventId={eventId}/>
          <Sup align="center">*Requires having the tokenproof app installed</Sup>
        </div>
        <PoapStationFooter/>
      </>}
    </div>
  )
};
