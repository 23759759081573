import {Header, POAPProductTitle} from "@poap/poap-components";
import {useViewport} from "@poap/poap-components/src/hooks/viewportChecker";
import routes from "../Routes";

export const PoapStationHeader = (props) => {
  const {eventId} = props;

  // Hack to fix "POAP POAP Station" product name on desktop,
  // this way we can continue using POAPProductTitle component out of the box
  const {width} = useViewport();
  const productTitle = (width > 768) ? "Station" : "POAP Station";

  return (
    <Header leftColumn={<POAPProductTitle productName={productTitle} productUrl={routes.homeForEvent(eventId)}/>}/>
  )
}
