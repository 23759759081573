import {useApiCall} from "./useApiCall";
import apiClient from "../BackofficeApiClient";
import {useCallback} from "react";

export const useEvent = (eventId) => {
  const fetchEvent = useCallback(() => {
    return apiClient.getEvent(eventId);
  }, [eventId]);

  const {data, loading} = useApiCall(fetchEvent);
  return {event: data, loading};
}
