import {LayeredText, Text} from "@poap/poap-components";
import {PriceBox} from "./PriceBox";


export const GetVoucherIntro = (props) => {
  const {event} = props;
  return (
    <>
      <div className="get-voucher-container">
        <div className="get-voucher-background-container">
          <div className="section-title-container -violet-colored-text">
            <LayeredText label="Get your"/>
            <LayeredText label="POAP"/>
            <LayeredText label="voucher"/>
          </div>
          <img className="get-voucher-background" alt="hero background" src="/get-voucher-background.svg"/>
        </div>
        <div className="get-voucher-text-container">
          <Text>Buy a POAP Voucher, which can be redeemed for a POAP and unique physical rewards.</Text>
        </div>
        <PriceBox value={event.price.value} currency={event.price.currency}/>
      </div>
    </>
  );
};
