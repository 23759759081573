import {Button, DynamicTag, Sup} from "@poap/poap-components";
import routes from "../Routes";


export const ClaimMethods = (props) => {
  const {event} = props;
  return (
    <div className="section-container -claim-it">
      <div className="subtitle-container -align-center">
        <DynamicTag tag="h3">Or claim it for free!</DynamicTag>
      </div>
      <div className="button-container -long-btn">
        <Button href={routes.claimVoucherForEvent(event.id)}
                leftIcon={
                  <img
                    className={"payment-method-button-icon-tokenproof"}
                    alt={"tokenproof"}
                    src={"/tokenproof-icon.png"}
                  />
                }
        >
          Check eligible POAPs
        </Button>
        <Sup align="center">
          If you own eligible POAPs you can claim your POAP coin for free using tokenproof!
        </Sup>
      </div>
    </div>
  );
};
